<template>
  <div>
    <KTCodePreview v-bind:title="'Cập nhật thông tin nhân sự'">
      <template v-slot:preview>
        <b-form
          ref="form"
          lazy-validation
        >
          <!-- Input session -->
          <div class="row">
            <div class="col-md-3">
              <b-row class="my-1">
                <b-col>
                  <b-form-group>
                    <label for="input-id">ID nhân sự:</label>
                    <b-form-input
                      size="sm"
                      placeholder="Nhập mã"
                      v-model="id"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div class="col-md-3">
              <b-row class="my-1">
                <b-col>
                  <b-form-group>
                    <label>Chức vụ:</label>
                    <b-form-select
                      required
                      v-model="position.selected"
                      :options="position.options"
                      size="sm"
                      value-field="id"
                      text-field="name"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div class="col-md-3">
              <b-row class="my-1">
                <b-col>
                  <b-form-group v-if="position.selected === 3">
                    <template>
                      <label>Khu vực:
                        <span class="text-danger">*</span>
                      </label>
                    </template>
                    <b-form-select
                      size="sm"
                      v-model="selectedArea"
                      :options="listArea"
                      value-field="id"
                      text-field="name"
                    >
                      <template v-slot:first>
                        <b-form-select-option
                          :value="null"
                          disabled
                        >-- Khu vực --</b-form-select-option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback>Vui lòng chọn khu vực</b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    v-else-if="position.selected === 2"
                    class="required-control"
                  >
                    <label>Vùng: </label>
                    <b-form-select
                      size="sm"
                      v-model="selectedRegion"
                      :options="listRegion"
                      value-field="id"
                      text-field="name"
                    >
                      <template v-slot:first>
                        <b-form-select-option
                          :value="null"
                          disabled
                        >-- Vùng --</b-form-select-option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback>Vui lòng chọn khu vực</b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    v-else
                    class="mb-0"
                  >
                    <label> Cửa hàng: </label>
                    <Autosuggest
                      :model="searchStore"
                      :suggestions="filteredOptions"
                      :placeholder="'cửa hàng'"
                      :limit="10"
                      @select="onSelectedStore"
                      @change="onInputChange"
                      suggestionName="name"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <div class="col-md-3">
              <b-row class="my-1">
                <b-col>
                  <b-form-group>
                    <label>Nhân viên phụ trách:</label>
                    <b-form-input
                      size="sm"
                      placeholder="Nhập nhân viên"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>

          <!-- Row 2 -->
          <div class="row">
            <div class="col-md-3">
              <b-row class="my-1">
                <b-col>
                  <b-form-group class="required-control">
                    <label>Tên nhân sự: </label>
                    <b-form-input
                      size="sm"
                      v-model="$v.fullName.$model"
                      :state="validateState('fullName')"
                      aria-describedby="input-fullName-live-feedback"
                      placeholder="Nhập tên nhân sự"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      v-if="!$v.fullName.minLength"
                      id="input-fullName-live-feedback"
                    >
                      Nhập tên nhân sự với ít nhất 3 kí tự!
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback
                      v-if="!$v.fullName.required"
                      id="input-fullName-live-feedback"
                    >Vui lòng nhập tên nhân sự!</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div class="col-md-3">
              <b-row class="my-1">
                <b-col>
                  <b-form-group>
                    <label>Mã nhân viên:</label>
                    <b-form-input
                      size="sm"
                      placeholder="Nhập mã nhân viên"
                      v-model="code"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div class="col-md-3">
              <b-row class="my-1">
                <b-col>
                  <label style="font-weight: 500px"> Ngày sinh: </label>
                  <b-input-group class="mb-3">
                    <date-picker
                      placeholder="Chọn ngày sinh"
                      class="form-control form-control-sm"
                      :config="dpConfigs.date"
                      v-model="birthDay"
                    ></date-picker>
                  </b-input-group>
                </b-col>
              </b-row>
            </div>

            <div class="col-md-3">
              <b-row class="my-1">
                <b-col>
                  <b-form-group id="input-group-active">
                    <label for="select-status">Trạng thái:</label>
                    <b-form-select
                      required
                      v-model="active.selected"
                      :options="active.options"
                      aria-describedby="input-active-live-feedback"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>

          <!-- Row 3 -->
          <div class="row">
            <div class="col-md-3">
              <b-row class="my-1">
                <b-col>
                  <b-form-group>
                    <label>SĐT:</label>
                    <b-form-input
                      style="width: -webkit-fill-available"
                      size="sm"
                      v-model="mobile"
                      placeholder="Nhập số điện thoại"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div class="col-md-3">
              <b-row class="my-1">
                <b-col>
                  <b-form-group>
                    <label>Email:</label>
                    <b-form-input
                      size="sm"
                      v-model="email"
                      placeholder="Nhập email"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <b-col cols="3">
              <b-form-group>
                <label style="font-weight: 500px">Danh sách cửa hàng:</label>
                <b-input-group>
                  <vue-multi-select
                    v-model="listStoreId"
                    :options="listStores"
                    placeholder="Chọn cửa hàng"
                    option-label="name"
                    @input="onChangeListStore()"
                  ></vue-multi-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <div class="col-md-3">
              <b-row class="my-1">
                <b-col>
                  <b-form-group>
                    <label>Bộ phận:</label>
                    <b-form-select
                      size="sm"
                      v-model="selectedDepartment"
                      :options="listDepartment"
                      value-field="id"
                      text-field="name"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null">-- Chọn bộ phận --</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div
              class="col-md-12"
              v-show="listTransferStoreForms.length"
            >
              <p class="mb-1">Lịch sử yêu cầu chuyển cửa hàng:</p>
              <b-row>
                <b-table
                  :items="listTransferStoreForms"
                  :fields="fields"
                  sort-by="createdAt"
                  bordered
                  hover
                  :busy="loadingTransferStoreForm"
                  class="mt-7"
                >
                  <template v-slot:table-busy>
                    <vcl-table
                      :speed="5"
                      :animate="true"
                      :columns="10"
                    ></vcl-table>
                  </template>

                  <template v-slot:cell(code)="row">
                    <a v-bind:href="getLinkDoc(row.item)">
                      <span>
                        {{ row.item.code }}
                      </span>
                    </a>
                  </template>
                  <template v-slot:cell(status)="row">
                    <StatusForm
                      :status="row.item.status"
                      :timestamp="row.item?.approvedAt"
                      :performer="row.item?.approvedByName"
                    />
                  </template>
                  <template v-slot:cell(createdAt)="row">
                    <p>
                      {{ row.item.createdByName }} - {{ row.item.createdAt }}
                    </p>
                  </template>
                </b-table>
              </b-row>
            </div>
          </div>

          <b-row>
            <b-col>
              <b-form-group label="Danh mục:">
                <b-input-group>
                  <vue-multi-select
                    v-model="selectedCates"
                    :options="categories"
                    placeholder="Chọn danh mục"
                    option-label="name"
                  ></vue-multi-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col></b-col>
            <b-col></b-col>
            <b-col></b-col>
          </b-row>
        </b-form>
      </template>
      <template v-slot:foot>
        <b-button
          style="fontweight: 600; width: 70px"
          variant="primary"
          size="sm"
          @click="onSubmit"
        >Lưu</b-button>

        <router-link
          to="/employees"
          tag="button"
        >
          <b-button
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
          >Hủy</b-button>
        </router-link>
      </template>
    </KTCodePreview>
  </div>
</template>
<style scoped>
.center-label label {
  margin-bottom: 0px;
}

.multi-select-wrapper {
  flex: 1 1 auto;
}

.form-group label {
  font-weight: 500;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import StatusForm from '@/view/components/transfer-store-forms/StatusForm.vue';
import moment from 'moment';
import Swal from 'sweetalert2';
import { validationMixin } from 'vuelidate';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { minLength, required } from 'vuelidate/lib/validators';

import VueMultiSelect from 'vue-simple-multi-select';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import { JOB_TITLE } from '@/utils/constants';
import { TRANSFER_STORE_FORM_STATUS } from '@/utils/enum';
import { VclTable } from 'vue-content-loading';

export default {
  mixins: [validationMixin],
  data() {
    return {
      selectedCates: [],
      categories: [],
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      formatted: '',
      selected: '',
      selectedStore: null,
      position: {
        selected: null,
        options: JOB_TITLE,
      },
      transferStoreFormStatus: TRANSFER_STORE_FORM_STATUS,
      active: {
        selected: null,
        options: [
          { value: null, text: 'Hãy chọn trạng thái' },
          { value: '1', text: 'Hoạt động' },
          { value: '0', text: 'Không hoạt động' },
        ],
      },
      id: '',
      fullName: '',
      mobile: '',
      birthDay: '',
      email: '',
      filteredOptions: [],
      options: [
        {
          data: [],
        },
      ],
      searchStore: '',
      listRegion: [],
      listArea: [],
      selectedArea: null,
      selectedRegion: null,
      code: '',
      listStoreId: [],
      listStores: [],
      listDepartment: [],
      selectedDepartment: null,
      listTransferStoreForms: [],
      loadingTransferStoreForm: false,
      fields: [
        {
          key: 'currentStoreName',
          label: 'Cửa hàng ban đầu',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '25%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'targetStoreName',
          label: 'Cửa hàng chuyển đến',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '25%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '25%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'createdAt',
          label: 'Người yêu cầu',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '25%',
          },
          tdClass: 'text-center',
        },
      ],
    };
  },
  components: {
    KTCodePreview,
    VueMultiSelect,
    Autosuggest,
    StatusForm,
    VclTable,
  },
  created() {
    this.getCategories();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Nhân sự', route: '/employees' },
      { title: 'Danh sách nhân sự', route: '/employees' },
      { title: 'Cập nhật nhân sự' },
    ]);
    this.id = this.$route.query.id;
    this.getEmployeeById();
    this.fetchStore();
    this.fetchArea();
    this.fetchRegion();
    this.fetchDeparment();
    this.fetchTransferStoreForms();
  },
  methods: {
    getCategories() {
      ApiService.query('category').then(({ data }) => {
        if (data.status === 1) {
          this.categories = data.data.list_caterogy;
        }
      });
    },
    fetchTransferStoreForms: async function () {
      this.loadingTransferStoreForm = true;
      ApiService.query(
        `transfer-store-forms/get-by-employee/${this.$route.query.id}`,
      )
        .then((res) => {
          const data = res.data.data;
          this.listTransferStoreForms = data;
        })
        .catch((err) => {
          makeToastFaile(err.response.data.message);
        })
        .finally(() => {
          this.loadingTransferStoreForm = false;
        });
    },
    onContext(ctx) {
      this.formatted = ctx.selectedFormatted;
      this.selected = ctx.selectedYMD;
    },
    fetchStore: async function () {
      this.options[0].data = [];
      ApiService.setHeader();
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        this.listStores = stores;
        this.options[0].data = stores;
        this.filteredOptions = [...this.options[0].data];
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.snackbar = true;
      }
    },
    onSubmit() {
      this.updateEmployee();
    },
    reset() {
      this.$refs.form.reset();
    },
    updateEmployee: async function () {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      let empStoreIds = '-1';
      if (this.listStoreId.length > 0) {
        empStoreIds = '';
        this.listStoreId.forEach((element, index) => {
          if (index === this.listStoreId.length - 1) {
            empStoreIds += element.id;
          } else {
            empStoreIds += element.id + ', ';
          }
        });
      }
      const data = {
        id: this.id,
        categories: this.selectedCates,
        fullName: this.$v.fullName.$model.trim(),
        birthDay:
          this.birthDay.length > 0
            ? moment(this.birthDay, 'DD/MM/YYYY').format('yyyy-MM-DD')
            : null,
        jobTitleId: this.position.selected,
        email: this.email ? this.email.trim() : '',
        store: this.selectedStore,
        active: this.active.selected,
        mobile: this.mobile,
        areaId: this.selectedArea,
        regionId: this.selectedRegion,
        listStoreId: empStoreIds,
        code: this.code ? this.code.trim() : '',
        departmentId: this.selectedDepartment,
      };

      ApiService.post('employees' + '/' + this.id, data).then((data) => {
        const { status, message } = data.data;
        if (status === 1) {
          makeToastSuccess(message);
          this.$router.push({
            name: 'list-employee',
          });
        } else {
          makeToastFaile(message);
        }
      });
    },
    getEmployeeById: async function () {
      ApiService.query('employees' + '/' + this.id).then((response) => {
        const dataRes = response.data.data;
        this.selectedCates = [...dataRes.categories];
        this.id = dataRes.id;
        this.$v.fullName.$model = dataRes.fullName;
        this.birthDay = dataRes.birthDay
          ? moment(String(dataRes.birthDay)).format('DD-MM-YYYY')
          : '';
        this.position.selected = dataRes.jobTitleId;
        this.email = dataRes.email;
        this.selectedStore = dataRes.storeId;
        this.active.selected = dataRes.isActive === true ? 1 : 0;
        this.mobile = dataRes.mobile;
        this.searchStore = dataRes.storeName;
        this.selectedArea = dataRes.areaId;
        this.selectedRegion = dataRes.regionId;
        this.code = dataRes.code;
        this.listStoreId = dataRes.listStore;
        this.selectedDepartment = dataRes.departmentId;
        //init list store can set
        this.options[0].data = [];
        this.listStoreId.map((element) => {
          this.options[0].data.push(element);
        });
        let tmp = [{ data: this.options[0].data }];
        this.filteredOptions = tmp;
      });
    },
    showSuccess: function () {
      Swal.fire({
        title: 'Thông báo',
        text: 'Cập nhật nhân sự thành công',
        icon: 'success',
        confirmButtonClass: 'btn btn-secondary',
      });
    },
    onInputChange(text) {
      this.searchStore = text;
      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptions = [...filteredData];
      if (!this.searchStore) {
        this.options[0].data = this.listStores;
        this.filteredOptions = [...this.options[0].data];
        this.selectedStore = null;
      }
    },
    onSelectedStore(option) {
      this.searchStore = option.item.name;
      this.selectedStore = option.item.id;
    },
    fetchArea: async function () {
      this.listArea = [];
      ApiService.setHeader();
      ApiService.get('/area').then((response) => {
        this.listArea = response.data.data;
      });
    },
    fetchRegion: async function () {
      this.listRegion = [];
      ApiService.setHeader();
      ApiService.get('/region').then((response) => {
        this.listRegion = response.data.data;
      });
    },
    onChangeListStore() {
      this.options[0].data = [];
      this.listStoreId.map((element) => {
        this.options[0].data.push(element);
      });
      let tmp = [{ data: this.options[0].data }];
      this.filteredOptions = tmp;
    },
    fetchDeparment: async function () {
      this.listDeparment = [];
      ApiService.get('/employees/departments').then((response) => {
        this.listDepartment = response.data.data;
      });
    },
  },
  validations: {
    fullName: {
      required,
      minLength: minLength(3),
    },
  },
};
</script>
